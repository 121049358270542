import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormValues } from '../../pages/CreateOffer.tsx';
import { TextInputField } from '../TextInputField.tsx';
import { Temporal } from 'temporal-polyfill';
import { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';

export function OfferDetailsFormPart({
    register,
    errors,
    setValue,
}: {
    register: UseFormRegister<FormValues>;
    errors: FieldErrors<FormValues>;
    setValue: UseFormSetValue<FormValues>;
}) {
    const intl = useIntl();
    const GERMAN_TIMEZONE = 'Europe/Berlin';
    const maxYears = 9;
    const minStartDateYear = Temporal.Now.plainDateISO(GERMAN_TIMEZONE).add({ years: 1 }).year;
    const maxStartDateYear = minStartDateYear + maxYears - 1;
    const startDateOptions = Array.from({ length: maxYears }, (_, index) => `${minStartDateYear + index}-01-01`);
    const defaultDurationOptions = Array.from({ length: maxYears }, (_, index) => index + 1);

    const [durationOptions, setDurationOptions] = useState(defaultDurationOptions);

    const inputStyle = 'font-bold text-base text-stone-500';

    return (
        <fieldset className="my-8">
            <h2>{intl.formatMessage({ id: 'deals.createOffer.offerDetails.title' })}</h2>
            <TextInputField
                {...register('general.offerName', {
                    required: true,
                    minLength: 3,
                })}
                label={intl.formatMessage({ id: 'deals.createOffer.offerDetails.nameInput.label' })}
                placeholder={intl.formatMessage({
                    id: 'deals.createOffer.offerDetails.nameInput.placeholder',
                })}
                bottomLeftErrorLabel={intl.formatMessage({
                    id: 'deals.createOffer.offerDetails.nameInput.errorMessage',
                })}
                hasError={!!errors?.general?.offerName}
                isFullWidth
            />
            <div className="grid grid-cols-3 gap-4 items-end">
                <label className="form-control w-full max-w-xs">
                    <div className="label">
                        <span className={`label-text ${inputStyle}`}>
                            {intl.formatMessage({
                                id: 'deals.createOffer.offerDetails.startDateInput.label',
                            })}
                        </span>
                    </div>
                    <select
                        {...register('general.startDate', {
                            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                const year = Temporal.PlainDate.from(event.target.value).year;
                                const newDurationOptions = new Array(maxStartDateYear + 1 - year)
                                    .fill(0)
                                    .map((_, index) => index + 1);
                                setDurationOptions(newDurationOptions);
                                setValue('general.duration', newDurationOptions[0]);
                            },
                        })}
                        className="select select-bordered"
                    >
                        {startDateOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {intl.formatDate(option, {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}
                            </option>
                        ))}
                    </select>
                </label>
                <label className="form-control w-full">
                    <div className="label">
                        <span className={`label-text ${inputStyle}`}>
                            {intl.formatMessage({
                                id: 'deals.createOffer.offerDetails.durationInput.label',
                            })}
                        </span>
                    </div>
                    <select
                        {...register('general.duration', { valueAsNumber: true })}
                        className="select select-bordered"
                    >
                        {durationOptions.map((option, index) => (
                            <option key={index}>{option}</option>
                        ))}
                    </select>
                </label>
                <div className="form-control justify-self-center">
                    <label className="label cursor-pointer">
                        <input
                            {...register('general.hasIncludedProvision')}
                            type="checkbox"
                            className="checkbox mr-2"
                        />
                        <span className="label-text">
                            {intl.formatMessage({
                                id: 'deals.createOffer.offerDetails.provisionCheckbox',
                            })}
                        </span>
                    </label>
                </div>
            </div>
        </fieldset>
    );
}
